import { DBObject } from './interfaces';
import { JobBenefit } from './job-benefit.model';

export type JobCustomField = {
  type: 'select' | 'text';
  title: string;
  key: string;
  required: boolean;
  meta?: any;
};

export class Job implements DBObject {
  path: string = '/job';
  private description: string;
  private content;
  private title: string;
  private wage: number;
  private career_changer: boolean;
  private type: string;
  private is_limited: boolean;
  private is_published: boolean;
  private begin: Date;
  _id: string;
  private company: string;
  private professions: Array<string>;
  private tags: Array<string>;
  private benefits: Array<JobBenefit>;
  private slug: string;
  private custom_fields?: JobCustomField[];
  internship: boolean;

  constructor(
    $description: string,
    $content: any,
    $title: string,
    $wage: number,
    $career_changer: boolean,
    $type: string,
    $is_limited: boolean,
    $is_published: boolean,
    $begin: Date,
    $id: string,
    $company: string = null,
    $professions: Array<string>,
    $tags: Array<string>,
    $benefits: Array<JobBenefit>,
    $isTraineePosition: boolean,
    $slug: string,
    $internship: boolean,
    $custom_fields?: JobCustomField[]
  ) {
    this.description = $description;
    this.content = $content;
    this.title = $title;
    this.wage = $wage;
    this.career_changer = $career_changer;
    this.type = $type;
    this.is_limited = $is_limited;
    this.is_published = $is_published;
    this.begin = $begin;
    this._id = $id;
    this.company = $company;
    this.professions = $professions;
    this.tags = $tags;
    this.benefits = $benefits;
    if ($isTraineePosition || $internship) {
      this.path = '/trainee/position';
    }
    this.slug = $slug;
    this.internship = $internship;
    this.custom_fields = $custom_fields;
  }

  static generate(json, isTraineePosition: boolean): Job {
    let {
      description,
      content,
      title,
      hourly_wage,
      career_changer,
      job_type,
      job_is_limited,
      is_published,
      begin,
      _id,
      company_id,
      professions,
      tags,
      benefits,
      slug,
      internship,
      custom_fields,
    } = json;
    return new Job(
      description,
      content,
      title,
      hourly_wage,
      career_changer,
      job_type,
      job_is_limited,
      is_published,
      new Date(begin),
      _id,
      company_id,
      professions,
      tags,
      JobBenefit.generateArray(benefits),
      isTraineePosition,
      slug,
      internship,
      custom_fields
    );
  }

  json(): any {
    return {
      description: this.description,
      content: this.content,
      title: this.title,
      hourly_wage: this.wage ? this.wage : 0,
      career_changer: this.career_changer,
      job_type: this.type,
      job_is_limited: this.is_limited,
      is_published: this.is_published,
      begin: this.$begin.toISOString(),
      _id: this._id,
      company_id: this.company,
      professions: this.professions,
      benefits: this.benefits,
      slug: this.slug,
      internship: this.internship,
      custom_fields: this.custom_fields,
    };
  }

  /**
   * Getter $description
   * @return {string}
   */
  public get $description(): string {
    return this.description;
  }

  /**
   * Getter $content
   * @return {string}
   */
  public get $content(): any {
    return this.content;
  }

  /**
   * Getter $internship
   * @return {string}
   */
  public get $internship(): any {
    return this.internship;
  }

  /**
   * Getter $title
   * @return {string}
   */
  public get $title(): string {
    return this.title;
  }

  /**
   * Getter $wage
   * @return {number}
   */
  public get $wage(): number {
    return this.wage;
  }

  /**
   * Getter $career_changer
   * @return {boolean}
   */
  public get $career_changer(): boolean {
    return this.career_changer;
  }

  /**
   * Getter $type
   * @return {string}
   */
  public get $type(): string {
    return this.type;
  }

  /**
   * Getter $is_limited
   * @return {boolean}
   */
  public get $is_limited(): boolean {
    return this.is_limited;
  }

  /**
   * Getter $is_published
   * @return {boolean}
   */
  public get $is_published(): boolean {
    return this.is_published;
  }

  /**
   * Getter $begin
   * @return {Date}
   */
  public get $begin(): Date {
    return this.begin;
  }

  /**
   * Getter $id
   * @return {string}
   */
  public get $id(): string {
    return this._id;
  }

  /**
   * Getter $company
   * @return {string}
   */
  public get $company(): string {
    return this.company;
  }

  /**
   * Getter $professions
   * @return {string}
   */
  public get $professions(): Array<string> {
    return this.professions;
  }

  /**
   * Getter $benefits
   * @return {string}
   */
  public get $benefits(): Array<JobBenefit> {
    return this.benefits;
  }

  /**
   * Setter $description
   * @param {string} value
   */
  public set $description(value: string) {
    this.description = value;
  }

  /**
   * Setter $content
   * @param {string} value
   */
  public set $content(value: any) {
    this.content = value;
  }

  /**
   * Setter $title
   * @param {string} value
   */
  public set $title(value: string) {
    this.title = value;
  }

  /**
   * Setter $wage
   * @param {number} value
   */
  public set $wage(value: number) {
    this.wage = value;
  }

  /**
   * Setter $career_changer
   * @param {boolean} value
   */
  public set $career_changer(value: boolean) {
    this.career_changer = value;
  }

  /**
   * Setter $type
   * @param {string} value
   */
  public set $type(value: string) {
    this.type = value;
  }

  /**
   * Setter $is_limited
   * @param {boolean} value
   */
  public set $is_limited(value: boolean) {
    this.is_limited = value;
  }

  /**
   * Setter $is_published
   * @param {boolean} value
   */
  public set $is_published(value: boolean) {
    this.is_published = value;
  }

  /**
   * Setter $begin
   * @param {Date} value
   */
  public set $begin(value: Date) {
    this.begin = value;
  }

  /**
   * Setter $id
   * @param {string} value
   */
  public set $id(value: string) {
    this._id = value;
  }

  /**
   * Setter $company
   * @param {string} value
   */
  public set $company(value: string) {
    this.company = value;
  }

  /**
   * Setter $company
   * @param {string} value
   */
  public set $professions(value: Array<string>) {
    this.professions = value;
  }

  /**
   * Setter $benefits
   * @param {string} value
   */
  public set $benefits(value: Array<JobBenefit>) {
    this.benefits = value;
  }

  public get $slug(): string {
    return this.slug;
  }
  public set $slug(value: string) {
    this.slug = value;
  }

  public get $custom_fields(): JobCustomField[] {
    return this.custom_fields || [];
  }
  public set $custom_fields(value: JobCustomField[]) {
    this.custom_fields = value;
  }
}
