import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Notification } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationSubject: Subject<Notification> = new Subject();

  constructor() {}

  getSubject(): Subject<Notification> {
    return this.notificationSubject;
  }

  showInfo(text: string, timeout = 5000) {
    let notification = new Notification(text, 'success', timeout);
    this.notificationSubject.next(notification);
  }

  showError(text: string, timeout = 5000) {
    let notification = new Notification(text, 'danger', timeout);
    this.notificationSubject.next(notification);
  }
}
