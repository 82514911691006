import { DBObject } from './interfaces';

export class Licence implements DBObject {
  json: () => any;
  _id: string;
  path: string = 'company/id/licenses';
  licences: Array<LicenceObj>;
  jobLimit: JobLimit;

  constructor(licences: Array<LicenceObj>, jobLimit: JobLimit) {
    this.licences = licences;
    this.jobLimit = jobLimit;
  }

  static generate(json) {
    return new Licence(json.licences, json.limits?.jobs);
  }
}

export interface LicenceObj {
  licence_name: string;
  jobs_max: number;
  valid_from?: string;
  valid_to?: string;
}

export interface JobLimit {
  limit: number;
  used: number;
}
