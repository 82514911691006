import { Component } from '@angular/core';
import { Company } from './models';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { SessionService } from './services/session.service';
import { UtilsService } from './services/utils.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'mehrmacher-webapp';

  constructor(
    private session: SessionService,
    private auth: AuthService,
    private utilsService: UtilsService, //DOnt remove, we need to init the Utils service here,
    private api: ApiService
  ) {}

  get _session() {
    return this.session._session;
  }

  isCompanyActive() {
    return this.session.isCompanyActive;
  }

  onClickAbo() {
    this.api
      .getCompany(this.session._session.company._id)
      .then((_c: Company) => {
        if (!_c._id) return;
        return this.api.getStripeSession(_c._id);
      })
      .then((res) => {
        window.open(res.url, '_blank');
      });
  }
}
