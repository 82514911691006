export const environment = {
  production: true,
  firebase_config: {
    apiKey: 'AIzaSyBkkXQn8sGr7HYBGaGAvIEbR1uuytME5L0',
    authDomain: 'mehrmacher.firebaseapp.com',
    databaseURL: 'https://mehrmacher.firebaseio.com',
    projectId: 'mehrmacher',
    storageBucket: 'mehrmacher.appspot.com',
    messagingSenderId: '694187359603',
    appId: '1:694187359603:web:84c01aaaefa97dc0a89906',
    measurementId: 'G-R5WT8NWGYS',
  },
  api: 'https://mehrmacher-api-staging-dot-mehrmacher.ew.r.appspot.com/api/v1',
  sites: 'http://35.242.193.86',
  website: 'https://staging.mehrmacher.de',
  useEmulators: false,
};
