import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { ApiService } from '../services/api.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private api: ApiService,
    private router: Router,
    private session: SessionService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      let session = this.session._session;
      if (!session.active) {
        let session_create = await this.session.initSession();
        if (!session_create) return false;
      }
    } catch (error) {
      console.error(error);
      this.router.navigateByUrl('/admin/login');
    }

    return this.api
      .getUser()
      .then(async (user) => {
        if (user.account_type === 'company') {
          return true;
        } else if (user.account_type === 'worker') {
          this.router.navigateByUrl('/admin/login?q=permission');
          return false;
        } else {
          this.router.navigateByUrl('/admin/login');
          return false;
        }
      })
      .catch((error) => {
        console.error(error);
        this.router.navigateByUrl('/admin/login');
        this.session._session.active = false;
        return false;
      });
  }
}
