<footer class="pt-4 my-md-5 pt-md-5 border-top container">
  <div class="row justify-content-center">
    <div class="col-lg-3 col-sm-12 col-md-4 text-center align-middle">
      <img
        class="mb-2 shadow-sm rounded"
        src="/assets/img/mm_logo.png"
        alt="mehrmacher"
        width="50"
        height="50"
      />
      <small class="d-block mb-3 text-muted">&copy; 2020</small>
    </div>
    <div class="col-lg-3 col-sm-12 col-md-4 d-flex justify-content-center">
      <ul class="list-unstyled text-small">
        <li>
          <a
            class="text-muted"
            href="https://www.mehrmacher.de/impressum.html"
            target="_blank"
            rel="noopener noreferrer"
            >Impressum</a
          >
        </li>
        <li>
          <a
            class="text-muted"
            href="https://www.mehrmacher.de/datenschutz.html"
            target="_blank"
            rel="noopener noreferrer"
            >Datenschutz</a
          >
        </li>
        <li>
          <a
            class="text-muted"
            href="https://www.mehrmacher.de/betrieblicheNutzungsbedingungen.html"
            target="_blank"
            rel="noopener noreferrer"
            >Nutzungsbedingungen</a
          >
        </li>
      </ul>
    </div>
  </div>
</footer>
