import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { AuthGuard } from './guards/auth.guard';

import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AbonnementComponent } from './core/abonnement/abonnement.component';

// https://github.com/angular/angularfire/blob/master/docs/emulators/emulators.md
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';

@NgModule({
  declarations: [AppComponent, AbonnementComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase_config),
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgbModule,
    ClipboardModule,
    NgMultiSelectDropDownModule,
  ],
  providers: [
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 48002] : undefined,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
