

export class ChatMessage {

    private _type: string;
    private _senderId: string;
    private _sent_at: string;
    private _payload: string;
    private _sent_at_readableDate: string;
    private _couldNotBeSent: boolean = false;

    constructor(type: string, senderId: string, sent_at: string, payload: string) {
        this._type = type;
        this._senderId = senderId;
        this._sent_at = sent_at;
        this._payload = payload;
    }

    static generateOneMessage(res): ChatMessage {
        return new ChatMessage(
            res.type,
            res.sender,
            res.send_at,
            res.payload
        )
    }

    static generateMultipleMessages(res: Array<any>): ChatMessage[] {
        let messages: ChatMessage[] = [];
        res?.forEach(message => {
            message = ChatMessage.generateOneMessage(message);
            if (message) {
                messages.push(message);
            }
        })
        return messages;
    }

    public get type(): string {
        return this._type;
    }
    public set type(value: string) {
        this._type = value;
    }

    public get senderId(): string {
        return this._senderId;
    }
    public set senderId(value: string) {
        this._senderId = value;
    }

    public get sent_at(): string {
        return this._sent_at;
    }
    public set sent_at(value: string) {
        this._sent_at = value;
    }

    public get payload(): string {
        return this._payload;
    }
    public set payload(value: string) {
        this._payload = value;
    }

    public get sent_at_readableDate(): string {
        if (!this._sent_at_readableDate) {
            this._sent_at_readableDate = new Date(this._sent_at).toLocaleString();
        }
        return this._sent_at_readableDate;
    }
    public set sent_at_readableDate(value: string) {
        this._sent_at_readableDate = value;
    }

    public get couldNotBeSent(): boolean {
        return this._couldNotBeSent;
    }
    public set couldNotBeSent(value: boolean) {
        this._couldNotBeSent = value;
    }
}