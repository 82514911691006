import { ToJSON } from './interfaces';

export class CPSection implements ToJSON {
  private title: string;
  private description: string;
  private img: string;

  constructor($title: string, $description: string, $img: string) {
    this.title = $title;
    this.description = $description;
    this.img = $img;
  }

  json() {
    return { title: this.title, description: this.description, img: this.img };
  }

  static generate({ title, description, img }): CPSection {
    return new CPSection(title, description, img);
  }

  /**
   * Getter $title
   * @return {string}
   */
  public get $title(): string {
    return this.title;
  }

  /**
   * Getter $description
   * @return {string}
   */
  public get $description(): string {
    return this.description;
  }

  /**
   * Getter $img
   * @return {string}
   */
  public get $img(): string {
    return this.img;
  }

  /**
   * Setter $title
   * @param {string} value
   */
  public set $title(value: string) {
    this.title = value;
  }

  /**
   * Setter $content
   * @param {string} value
   */
  public set $description(value: string) {
    this.description = value;
  }

  /**
   * Setter $img
   * @param {string} value
   */
  public set $img(value: string) {
    this.img = value;
  }
}
