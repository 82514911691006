import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './nav/sidebar/sidebar.component';
import { MobileComponent } from './previews/mobile/mobile.component';
import { DesktopComponent } from './previews/desktop/desktop.component';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  declarations: [
    FooterComponent,
    NavComponent,
    SidebarComponent,
    MobileComponent,
    DesktopComponent,
    NotificationComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    FooterComponent,
    NavComponent,
    SidebarComponent,
    MobileComponent,
    DesktopComponent,
    NotificationComponent,
  ],
})
export class SharedModule {}
