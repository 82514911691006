import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SessionService } from 'src/app/services/session.service';
import { SidebarNavService } from 'src/app/sidebar-nav.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit {
  constructor(
    private session: SessionService,
    private auth: AuthService,
    private sideService: SidebarNavService,
    private api: ApiService
  ) {}

  get _session() {
    return this.session._session;
  }

  signOut() {
    this.auth.logout();
  }

  changeCompany(company) {
    this.session.setCompany(company);
  }

  getSidebarLinks() {
    return this.sideService.getLinks();
  }

  onClickStripe() {
    this.api
      .getCompany(this.session._session.company._id)
      .then((_c: Company) => {
        if (!_c._id) return;
        return this.api.getStripeSession(_c._id);
      })
      .then((res) => {
        window.open(res.url, '_blank');
      });
  }

  get canCreate() {
    return this.auth.claims?.isSU;
  }

  ngOnInit(): void {}
}
