<div class="position-relative" style="width: 450px">
  <img src="assets/img/iphone_mock.png" id="mobile-device" class="w-100" />
  <iframe
    [src]="url"
    title="Mobile Preview"
    frameborder="0"
    id="mobile-iframe"
    class="w-100 h-100 rounded"
  ></iframe>
</div>
