<div #sidebarElement class="bg-light active" style="z-index: 1" id="sidebar">
  <div class="wrapper position-fixed border-right">
    <div class="row w-100 h-100 m-0">
      <div class="col-10">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <li class="nav-item pointer px-3" *ngFor="let link of getLinks()">
            <a
              class="nav-link"
              [routerLink]="link.routerLink"
              [queryParams]="link.queryParams"
              routerLinkActive="active"
              >{{ link.name }}</a
            >
          </li>
        </ul>
        <div class="w-100 pt-5">
          <small class="text-muted">{{ versionInfo }}</small>
        </div>
      </div>
      <div
        (click)="toggle()"
        class="open-sidebar-col col-2 pointer p-0 d-flex justify-content-end"
      >
        <img
          [@openClose]="isOpen ? 'open' : 'closed'"
          id="openCloseIcon"
          width="15px"
          src="assets/svg/icon_chefron_right.svg"
          alt="toggle menu"
        />
      </div>
    </div>
  </div>
</div>
