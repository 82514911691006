import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Notification } from 'src/app/models/notification.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate(
          '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: '*' })
        ),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: '*', height: '*' }),
        animate(
          '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)',
            opacity: 0,
            height: '0px',
          })
        ),
      ]),
    ]),
  ],
})
export class NotificationComponent implements OnInit {
  notifications: Notification[] = [];

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.getSubject().subscribe((not) => {
      this.notifications.push(not);
      if (not.timeout !== 0) {
        setTimeout(() => this.close(not), not.timeout);
      }
    });
  }

  close(not: Notification) {
    this.notifications = this.notifications.filter(
      (notification) => notification != not
    );
  }
}
