import { Subscriber } from 'rxjs';

export class Subscription {
  type: string;
  valid_from: Date;
  valid_until: Date;
  rights: Array<string>;

  constructor(
    type: string,
    valid_from: string,
    valid_until: string,
    rights: Array<string>
  ) {
    this.type = type;
    this.valid_from = new Date(valid_from);
    this.valid_until = new Date(valid_until);
    this.rights = rights;
  }

  static generate(json): Subscription {
    return new Subscription(
      json.type,
      json.valid_from,
      json.valid_until,
      json.rights
    );
  }
  static generateMultiple(jsonArray): Array<Subscription> {
    let arr = [];
    jsonArray?.forEach((element) => {
      arr.push(Subscription.generate(element));
    });
    return arr;
  }
}
