import { Component, OnInit } from '@angular/core';
import { Licence, LicenceObj } from 'src/app/models/license.model';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-abonnement',
  templateUrl: './abonnement.component.html',
  styleUrls: ['./abonnement.component.css'],
})
export class AbonnementComponent implements OnInit {
  licence: Licence;

  constructor(private api: ApiService, private session: SessionService) {}

  ngOnInit(): void {
    this.loadLicence();
  }

  loadLicence() {
    this.api.getLicenses(this.session._session.company._id).then((res) => {
      this.licence = res;
      console.log(this.licence);
    });
  }

  getLicenceName(licenceObj: LicenceObj): string {
    switch (licenceObj.licence_name) {
      case 'free':
        return 'Free';
      case 'basis':
        return 'Basis';
      case 'profi':
        return 'Profi';
      case 'premium':
        return 'Premium';
      default:
        return licenceObj.licence_name;
    }
  }
}
