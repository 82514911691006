import { DBObject } from './interfaces';
import { Subscription } from './subscription.model';

export class Company implements DBObject {
  path = '/company';

  _id: string;
  private account_type: string = 'company';
  private description: string;
  private homepage: string;
  private first_name: string;
  private last_name: string;
  private contact_email: string;
  private company: string;
  private street: string;
  private house_number: string;
  private address_info: string;
  private zip_code: string;
  private city: string;
  private country: string;
  private phone: string;
  private industry: string;
  private foundation_year: Date;
  private company_number: string;
  private img_logo_url: string;
  private img_banner_url: string;
  private profile_progress: number;
  private _active_subscription: boolean;
  private subscriptions: Array<Subscription>;
  private _slug: string;

  constructor(
    id: string,
    $description: string,
    $homepage: string,
    $contact_email: string,
    $company: string,
    $street: string,
    $house_number: string,
    $address_info: string,
    $zip_code: string,
    $city: string,
    $country: string,
    $phone: string,
    $industry: string,
    $foundation_year: Date,
    $company_number: string,
    $img_logo_url: string,
    $img_banner_url: string,
    $profile_progress: number,
    $first_name: string,
    $last_name: string,
    $active_subscription: boolean,
    $subscriptions: Array<Subscription>,
    $slug: string
  ) {
    this._id = id;
    this.description = $description;
    this.homepage = $homepage;
    this.contact_email = $contact_email;
    this.company = $company;
    this.street = $street;
    this.house_number = $house_number;
    this.address_info = $address_info;
    this.zip_code = $zip_code;
    this.city = $city;
    this.country = $country;
    this.phone = $phone;
    this.industry = $industry;
    this.foundation_year = $foundation_year;
    this.company_number = $company_number;
    this.img_logo_url = $img_logo_url;
    this.profile_progress = $profile_progress;
    this.first_name = $first_name;
    this.last_name = $last_name;
    this.img_banner_url = $img_banner_url;
    this.active_subscription = $active_subscription;
    this.subscriptions = $subscriptions;
    this.slug = $slug;
  }

  static generate(json): Company {
    const {
      _id,
      description,
      homepage,
      contact_email,
      company,
      street,
      house_number,
      address_info,
      zip_code,
      city,
      country,
      phone,
      industry,
      foundation_year,
      company_number,
      img_logo_url,
      img_banner_url,
      profile_progress,
      first_name,
      last_name,
      active_subscription,
      subscriptions,
      slug,
    } = json;
    return new Company(
      _id,
      description,
      homepage,
      contact_email,
      company,
      street,
      house_number,
      address_info,
      zip_code,
      city,
      country,
      phone,
      industry,
      new Date(foundation_year),
      company_number,
      img_logo_url,
      img_banner_url,
      profile_progress,
      first_name,
      last_name,
      active_subscription,
      Subscription.generateMultiple(subscriptions),
      slug
    );
  }

  json() {
    return {
      _id: this._id,
      description: this.description,
      homepage: this.homepage,
      contact_email: this.contact_email,
      company: this.company,
      street: this.street,
      house_number: this.house_number,
      address_info: this.address_info,
      zip_code: this.zip_code,
      city: this.city,
      country: this.country,
      phone: this.phone,
      industry: this.industry,
      foundation_year: this.foundation_year?.toISOString(),
      company_number: this.company_number,
      img_logo_url: this.img_logo_url,
      img_banner_url: this.img_banner_url,
      profile_progress: this.profile_progress,
      first_name: this.first_name,
      last_name: this.last_name,
      account_type: this.account_type,
      subscriptions: this.subscriptions,
      slug: this.slug,
    };
  }

  /**
   * Getter $account_type
   * @return {string }
   */
  public get $account_type(): string {
    return this.account_type;
  }

  /**
   * Getter $img_banner_url
   * @return {string}
   */
  public get $img_banner_url(): string {
    return this.img_banner_url;
  }

  /**
   * Setter $account_type
   * @param {string } value
   */
  public set $account_type(value: string) {
    this.account_type = value;
  }

  /**
   * Setter $img_banner_url
   * @param {string} value
   */
  public set $img_banner_url(value: string) {
    this.img_banner_url = value;
  }

  public get $first_name(): string {
    return this.first_name;
  }

  public set $first_name(value: string) {
    this.first_name = value;
  }

  public get $last_name(): string {
    return this.last_name;
  }

  public set $last_name(value: string) {
    this.last_name = value;
  }

  /**
   * Getter $description
   * @return {string}
   */
  public get $description(): string {
    return this.description;
  }

  /**
   * Setter $description
   * @param {string} value
   */
  public set $description(value: string) {
    this.description = value;
  }

  /**
   * Getter id
   * @return {string}
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Getter $homepage
   * @return {string}
   */
  public get $homepage(): string {
    return this.homepage;
  }

  /**
   * Getter $contact_email
   * @return {string}
   */
  public get $contact_email(): string {
    return this.contact_email;
  }

  /**
   * Getter $company
   * @return {string}
   */
  public get $company(): string {
    return this.company;
  }

  /**
   * Getter $street
   * @return {string}
   */
  public get $street(): string {
    return this.street;
  }

  /**
   * Getter $house_number
   * @return {string}
   */
  public get $house_number(): string {
    return this.house_number;
  }

  /**
   * Getter $address_info
   * @return {string}
   */
  public get $address_info(): string {
    return this.address_info;
  }

  /**
   * Getter $zip_code
   * @return {string}
   */
  public get $zip_code(): string {
    return this.zip_code;
  }

  /**
   * Getter $city
   * @return {string}
   */
  public get $city(): string {
    return this.city;
  }

  /**
   * Getter $country
   * @return {string}
   */
  public get $country(): string {
    return this.country;
  }

  /**
   * Getter $phone
   * @return {string}
   */
  public get $phone(): string {
    return this.phone;
  }

  /**
   * Getter $industry
   * @return {string}
   */
  public get $industry(): string {
    return this.industry;
  }

  /**
   * Getter $foundation_year
   * @return {Date}
   */
  public get $foundation_year(): Date {
    return this.foundation_year;
  }

  /**
   * Getter $company_number
   * @return {string}
   */
  public get $company_number(): string {
    return this.company_number;
  }

  /**
   * Getter $img_logo_url
   * @return {string}
   */
  public get $img_logo_url(): string {
    return this.img_logo_url;
  }

  /**
   * Getter $profile_progress
   * @return {number}
   */
  public get $profile_progress(): number {
    return this.profile_progress;
  }

  /**
   * Setter id
   * @param {string} value
   */
  public set id(value: string) {
    this._id = value;
  }

  /**
   * Setter $homepage
   * @param {string} value
   */
  public set $homepage(value: string) {
    this.homepage = value;
  }

  /**
   * Setter $contact_email
   * @param {string} value
   */
  public set $contact_email(value: string) {
    this.contact_email = value;
  }

  /**
   * Setter $company
   * @param {string} value
   */
  public set $company(value: string) {
    this.company = value;
  }

  /**
   * Setter $street
   * @param {string} value
   */
  public set $street(value: string) {
    this.street = value;
  }

  /**
   * Setter $house_number
   * @param {string} value
   */
  public set $house_number(value: string) {
    this.house_number = value;
  }

  /**
   * Setter $address_info
   * @param {string} value
   */
  public set $address_info(value: string) {
    this.address_info = value;
  }

  /**
   * Setter $zip_code
   * @param {string} value
   */
  public set $zip_code(value: string) {
    this.zip_code = value;
  }

  /**
   * Setter $city
   * @param {string} value
   */
  public set $city(value: string) {
    this.city = value;
  }

  /**
   * Setter $country
   * @param {string} value
   */
  public set $country(value: string) {
    this.country = value;
  }

  /**
   * Setter $phone
   * @param {string} value
   */
  public set $phone(value: string) {
    this.phone = value;
  }

  /**
   * Setter $industry
   * @param {string} value
   */
  public set $industry(value: string) {
    this.industry = value;
  }

  /**
   * Setter $foundation_year
   * @param {Date} value
   */
  public set $foundation_year(value: Date) {
    this.foundation_year = value;
  }

  /**
   * Setter $company_number
   * @param {string} value
   */
  public set $company_number(value: string) {
    this.company_number = value;
  }

  /**
   * Setter $img_logo_url
   * @param {string} value
   */
  public set $img_logo_url(value: string) {
    this.img_logo_url = value;
  }

  /**
   * Setter $profile_progress
   * @param {number} value
   */
  public set $profile_progress(value: number) {
    this.profile_progress = value;
  }

  public get active_subscription(): boolean {
    return this._active_subscription;
  }
  public set active_subscription(value: boolean) {
    this._active_subscription = value;
  }

  public get $subscriptions(): Array<Subscription> {
    return this.subscriptions;
  }
  public set $subscriptions(value: Array<Subscription>) {
    this.subscriptions = value;
  }

  public get slug(): string {
    return this._slug;
  }
  public set slug(value: string) {
    this._slug = value;
  }
}
