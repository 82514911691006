import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { Company } from '../models';
import { ApiService } from './api.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  static instance: UtilsService;
  constructor(private storage: AngularFireStorage, private router: Router) {
    UtilsService.instance = this;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  spinner() {
    return '/assets/img/mm_logo_animated_white.gif';
  }

  getImageDownloadUrl(url: string) {
    return this.storage.storage
      .refFromURL(`gs://${url}`)
      .getDownloadURL()
      .then((downloadUrl) => {
        return downloadUrl;
      });
  }

  isTraineeRoute(): boolean {
    return this.router.url.includes('/trainee-jobs');
  }
}
