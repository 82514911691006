export class Notification {
  timeout;
  text;
  color;

  constructor(text, color, timeout) {
    this.text = text;
    this.color = color;
    this.timeout = timeout;
  }
}
