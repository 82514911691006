import { Injectable } from '@angular/core';
import { SessionService } from './services/session.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarNavService {
  links = [
    {
      name: 'Betrieb',
      routerLink: '/company',
    },
    {
      name: 'Stellenanzeigen',
      routerLink: '/jobs',
    },
    {
      name: 'Azubi- und Praktikum-Stellenanzeigen',
      routerLink: '/trainee-jobs',
      queryParams: {},
    },
    {
      name: 'Bewerbungen',
      routerLink: '/chat-list',
    },
    {
      name: 'Abonnement',
      routerLink: '/abonnement',
    },
  ];

  constructor(private session: SessionService) {}

  getLinks() {
    let rights: Array<string> = this.session.getAllSubscriptionRights();
    if (rights == null) {
      return;
    }
    let tempLinks = [];
    this.links.forEach((el) => {
      tempLinks.push(el);
    });
    let index = -1;
    if (!rights?.includes('trainee_position_creation')) {
      index = tempLinks.findIndex(
        (element) => element.routerLink == '/trainee-jobs'
      );
      if (index >= 0) {
        tempLinks.splice(index, 1);
      }
    }

    if (!rights?.includes('job_creation')) {
      index = tempLinks.findIndex((element) => element.routerLink == '/jobs');
      if (index >= 0) {
        tempLinks.splice(index, 1);
      }
    }
    return tempLinks;
  }
}
