import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'company', pathMatch: 'full' },
  {
    path: 'admin',
    loadChildren: () =>
      import('./core/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('./core/jobs/jobs.module').then((m) => m.JobsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'trainee-jobs',
    loadChildren: () =>
      import('./core/jobs/jobs.module').then((m) => m.JobsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./core/company/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'career',
    loadChildren: () =>
      import('./core/career/career.module').then((m) => m.CareerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'chat-list',
    loadChildren: () =>
      import('./core/chat-list/chat-list.module').then((m) => m.ChatListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'abonnement',
    loadChildren: () =>
      import('./core/abonnement/abonnement-routing.module').then(
        (m) => m.AbonnementRoutingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/admin/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
