import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'preview-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.css'],
})
export class DesktopComponent implements OnInit {
  @Input()
  url: string;

  constructor() {}

  ngOnInit(): void {}
}
