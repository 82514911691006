import { DBObject } from './interfaces';
import { CPSection } from './cpSection.model';

export class CareerPage implements DBObject {
  _id: string;
  get path(): string {
    return `/company/${this.company_id}/career`;
  }
  private style: any;
  private sections: CPSection[];
  private company_id: string;

  constructor(
    $style: any,
    $sections: Array<CPSection>,
    $company_id: string,
    $_id: string = undefined
  ) {
    this.style = $style;
    this.sections = $sections || [];
    this.company_id = $company_id;
    this._id = $_id;
  }

  json() {
    return {
      company_id: this.company_id,
      style: this.style,
      sections: this.sections.map((x) => x.json()),
    };
  }

  static generate({ company_id, style, sections, _id }): CareerPage {
    return new CareerPage(
      style,
      sections.map(CPSection.generate),
      company_id,
      _id
    );
  }

  /**
   * Getter $style
   * @return {any}
   */
  public get $style(): any {
    return this.style;
  }

  /**
   * Getter $sections
   * @return {[CPSection]}
   */
  public get $sections(): CPSection[] {
    return this.sections;
  }

  /**
   * Getter $company_id
   * @return {string}
   */
  public get $company_id(): string {
    return this.company_id;
  }

  /**
   * Setter $style
   * @param {any} value
   */
  public set $style(value: any) {
    this.style = value;
  }

  /**
   * Setter $sections
   * @param {[CPSection]} value
   */
  public set $sections(value: CPSection[]) {
    this.sections = value;
  }

  /**
   * Setter $company_id
   * @param {string} value
   */
  public set $company_id(value: string) {
    this.company_id = value;
  }
}
