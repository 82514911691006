export class JobBenefit {
  private key: string;
  private title: string;
  private icon: string;

  constructor(
    $key: string,
    $title: string,
    $icon: string,
  ) {
    this.key = $key;
    this.title = $title;
    this.icon = $icon;
  }

  static generateArray(json): Array<JobBenefit> {
    if (!json) {
      return []
    }
    let benefitArray: Array<JobBenefit> = []
    for (let benefit of json) {
      benefitArray.push(this.generate(benefit));
    }
    return benefitArray;
  }

  static generate(json): JobBenefit {
    if (!json) {
      return null;
    }
    let {
      key,
      title,
      icon,
    } = json;
    return new JobBenefit(
      key,
      title,
      icon,
    );
  }

  json(): any {
    return {
      key: this.key,
      title: this.title,
      icon:this.icon
    }
  };

  /**
   * Getter $key
   * @return {string}
   */
   public get $key(): string {
    return this.key;
  }
  /**
   * Getter $title
   * @return {string}
   */
   public get $title(): string {
    return this.title;
  }
  /**
   * Getter $icon
   * @return {string}
   */
   public get $icon(): string {
    return this.icon;
  }


  /**
   * Setter $key
   * @param {string} value
   */
   public set $key(value: string) {
    this.key = value;
  }
  /**
   * Setter $title
   * @param {string} value
   */
  public set $title(value: string) {
    this.title = value;
  }
  /**
   * Setter $icon
   * @param {string} value
   */
  public set $icon(value: string) {
    this.icon = value;
  }
}
