import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SidebarNavService } from 'src/app/sidebar-nav.service';
import { version } from 'src/app/runtime';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'rotate(180deg)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      transition('open <=> closed', [animate('0.3s linear')]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  @ViewChild('sidebarElement') sidebarElement: ElementRef;

  isOpen = true;
  v = `v${version.version}`;
  b: string =
    version.build !== '$BUILD' ? ` - ${version.build.substring(0, 7)}` : '';

  p = version.prod ? '' : ' - staging';
  versionInfo: string = this.v + this.b + this.p;

  constructor(private sideService: SidebarNavService) {}

  ngOnInit(): void {}

  toggle() {
    if (this.sidebarElement.nativeElement.classList.contains('active')) {
      this.isOpen = false;
      this.sidebarElement.nativeElement.classList.remove('active');
    } else {
      this.isOpen = true;
      this.sidebarElement.nativeElement.classList.add('active');
    }
  }

  getLinks() {
    return this.sideService.getLinks();
  }
}
