<div class="messageContainer">
  <div [@slideInOut] class="p-1 w-100" *ngFor="let not of notifications">
    <div class="bg-success rounded p-3" *ngIf="not.color == 'success'">
      {{ not.text }}
    </div>
    <div class="bg-danger text-white rounded p-3" *ngIf="not.color == 'danger'">
      {{ not.text }}
    </div>
  </div>
</div>
