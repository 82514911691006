import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Job, Company } from '../models';
import { DBObject } from '../models/interfaces';
import { CareerPage } from '../models/careerPage.model';
import { ChatListItem } from '../models/chatListItem.model';
import { ChatRoom } from '../models/chatRoom.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { Licence } from '../models/license.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, public afAuth: AngularFireAuth) {}

  getConfig(): Promise<any> {
    return this.getUserToken().then((token) => {
      if (token) {
        localStorage.setItem('token', token);
      } else {
        token = localStorage.getItem('token');
      }
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Application-Type': 'web-app',
        }),
      };
    });
  }

  getAccessToken(): Promise<string> {
    return this.getUserToken().then((token) => {
      if (token) {
        localStorage.setItem('token', token);
      } else {
        token = localStorage.getItem('token');
      }
      return token;
    });
  }

  getUserToken(): Promise<string> {
    return this.afAuth.currentUser.then((user) => {
      return user?.getIdToken();
    });
  }

  async getStripeSession(companyId) {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/company/${companyId}/payment/session`, config)
      .toPromise();
    return _res;
  }

  async testAuth() {
    let config = await this.getConfig();
    return this.http.get(`${environment.api}/auth/test`, config);
  }

  async getCompany(_id): Promise<Company> {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(
        `${environment.api}/company/${_id}/edit`,
        Object.assign(config, { observe: 'response' })
      )
      .toPromise();
    return Company.generate(_res.body);
  }

  async getLicenses(company_id): Promise<Licence> {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(
        `${environment.api}/company/${company_id}/licences`,
        Object.assign(config, { observe: 'response' })
      )
      .toPromise();
    return Licence.generate(_res.body);
  }

  async getCompanies() {
    let config = await this.getConfig();
    let res: any = await this.http
      .get(`${environment.api}/user/companies`, config)
      .toPromise()
      .then((_c) => {
        return _c;
      });
    return res;
  }

  async createUser(first_name, last_name, options = { responseType: 'text' }) {
    let config = await this.getConfig();
    let res: any = await this.http
      .post(
        `${environment.api}/user`,
        { first_name, last_name, account_type: 'company' },
        Object.assign(config, options)
      )
      .toPromise();
    return res;
  }

  async getUser() {
    let config = await this.getConfig();
    let res: any = await this.http
      .get(`${environment.api}/user`, config)
      .toPromise();
    return res;
  }

  async getJobs(_cId, numberOfJobs = 1000): Promise<Job[]> {
    let config = await this.getConfig();
    config['params'] = {
      page_size: numberOfJobs,
    };
    let _res: any = await this.http
      .get(`${environment.api}/company/${_cId}/jobs`, config)
      .toPromise();
    return _res.data.map((el) => Job.generate(el, false));
  }

  async getJob(_id): Promise<Job> {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/job/${_id}`, config)
      .toPromise();
    return Job.generate(_res, false);
  }

  async getTraineeJobs(_cId, numberOfJobs = 1000): Promise<Job[]> {
    let config = await this.getConfig();
    config['params'] = {
      page_size: numberOfJobs,
    };
    let _res: any = await this.http
      .get(`${environment.api}/company/${_cId}/trainee/positions`, config)
      .toPromise();
    return _res.data.map((el) => Job.generate(el, true));
  }

  async joinCompany(company, key): Promise<boolean> {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(
        `${environment.api}/company/${company}/access?pass=${key}`,
        Object.assign(config, { observe: 'response' })
      )
      .toPromise();
    return _res.status === 200;
  }

  async getInvitationLink(company): Promise<string> {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get<any>(`${environment.api}/company/${company}/invite`, config)
      .toPromise();
    return _res.invitationLink.split('pass=')[1];
  }

  async getCareerPage(company): Promise<CareerPage> {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/company/${company}/career`, config)
      .toPromise();
    return CareerPage.generate(_res);
  }

  async getChatList(company_id: string) {
    if (!company_id) return;
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/chatrooms/?company_id=${company_id}`, config)
      .toPromise();
    return ChatListItem.generateMultipleItems(_res);
  }

  async getChatRoom(id: string, company_id: string) {
    if (!company_id) return;
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/chatroom/${id}?company_id=${company_id}`, config)
      .toPromise();
    return ChatRoom.generate(_res);
  }

  async addChatMessage(id: string, text: string, company_id: string) {
    if (!company_id) return;
    let config = await this.getConfig();
    let _res: any = await this.http
      .post(
        `${environment.api}/chatroom/${id}/message?company_id=${company_id}`,
        { message: text },
        config
      )
      .toPromise();
    return _res;
  }

  async getMessages(
    chatRoomId: string,
    company_id: string,
    timestampBefore?: string,
    timestampAfter?: string,
    size?: number
  ) {
    if (!company_id) return;
    timestampBefore = timestampBefore || '';
    timestampAfter = timestampAfter || '';
    let sizeString = size?.toString() || '';

    let config = await this.getConfig();
    let _res: any = await this.http
      .get(
        `${environment.api}/chatroom/${chatRoomId}/messages?company_id=${company_id}&before=${timestampBefore}&after=${timestampAfter}&size=${sizeString}`,
        config
      )
      .toPromise();
    return _res;
  }

  async putChatroomACK(id: string, timestamp: string, company_id: string) {
    if (!company_id) return;
    let config = await this.getConfig();
    let _res = await this.http
      .put(
        `${environment.api}/chatroom/${id}/ack?company_id=${company_id}&until=${timestamp}`,
        {},
        config
      )
      .toPromise();
    return _res;
  }

  async getApplicationDetail(id: string) {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/application/${id}`, config)
      .toPromise();
    return _res;
  }

  async update(obj: DBObject, options: any = {}, hideId = false) {
    let config = await this.getConfig();
    let suffix = hideId ? '' : `/${obj._id}`;
    return this.http
      .patch(
        `${environment.api}${obj.path}${suffix}`,
        obj.json(),
        Object.assign(config, options)
      )
      .toPromise();
  }

  async create(obj: DBObject, options: any = {}) {
    let config = await this.getConfig();
    return this.http
      .post(
        `${environment.api}${obj.path}`,
        obj.json(),
        Object.assign(config, options)
      )
      .toPromise();
  }

  async deleteJob(job: Job) {
    let config = await this.getConfig();
    return this.http
      .delete(`${environment.api}/job/${job.$id}`, config)
      .toPromise();
  }

  async deleteChat(id: string, company_id: string) {
    if (!company_id) return;
    let config = await this.getConfig();
    return this.http
      .delete(
        `${environment.api}/chatroom/${id}?company_id=${company_id}`,
        config
      )
      .toPromise();
  }

  async getJobCategories() {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/categories/jobs`, config)
      .toPromise();
    return _res;
  }

  async getJobBenefits() {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/categories/employee/benefits`, config)
      .toPromise();
    return _res;
  }

  async getUploadURL(
    company,
    path: string = 'company_logo',
    subtype: string = 'jpeg',
    n = 0
  ): Promise<string> {
    let config = await this.getConfig();
    return this.http
      .get<string>(
        `${environment.api}/utils/image-upload-url?path=${path}&company=${company}&subtype=${subtype}&n=${n}`,
        config
      )
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }

  uploadFile(file: any, url: string, subtype: string) {
    let headersForBinary = new HttpHeaders();
    headersForBinary = headersForBinary.set('Content-Type', `image/${subtype}`);
    return this.http
      .put(url, file, {
        observe: 'response',
        headers: headersForBinary,
      })
      .toPromise();
  }

  async listApplicationAssets(applicationId: string): Promise<string[]> {
    let config = await this.getConfig();
    let _res: any = await this.http
      .get(`${environment.api}/application/${applicationId}/files`, config)
      .toPromise()
      .catch((e) => {
        return [];
      });
    return _res as string[];
  }

  async downloadApplicationAsset(
    applicationId: string,
    assetName: string
  ): Promise<any> {
    let config = await this.getConfig();
    return this.http
      .get(
        `${environment.api}/application/${applicationId}/file?filename=${assetName}`,
        { ...config, responseType: 'blob' as 'json' }
      )
      .toPromise();
  }

  async exportApplication(applicationId: string): Promise<any> {
    let config = await this.getConfig();
    return this.http
      .get(`${environment.api}/application/${applicationId}/export`, {
        ...config,
        responseType: 'blob' as 'json',
      })
      .toPromise();
  }
}
