import { ChatMessage } from '.'
import { ChatParticipant } from '.'


export class ChatRoom {

    private _id: string;
    private _participants: ChatParticipant[];
    private _meta;
    private _state: string;
    private _receiverId: string;
    private _messages: ChatMessage[];
    private _created_on: string;


    constructor(id: string, participants: ChatParticipant[], meta, state: string, receiverId: string, messages: ChatMessage[], created_on: string) {
        this._id = id;
        this._participants = participants;
        this._meta = meta;
        this._state = state;
        this._receiverId = receiverId;
        this._messages = messages;
        this._created_on = created_on;
    }

    static generate(res) {
        return new ChatRoom(
            res._id,
            ChatParticipant.generateMultipleParticipants(res.participants),
            res.meta,
            res.state,
            res.recived_as,
            ChatMessage.generateMultipleMessages(res.messages),
            res.created_on
        )
    }

    /**
     * Adds a message to the chatroom if it doesn't already exists.
     * It also puts the message to the place in the array, so the messages are sorted by their sent_at-Date
     * 
     * @param message The message you want to add.
     * @param addToStartOfArray If true, it adds the message to start, else to the end of the array. 
     */
    addMessage(message: ChatMessage, addToStartOfArray = false) {
        if (this.messages.length <= 0) {
            this.messages.push(message);
        } else if (!this.messageAlreadyExists(message)) {
            let m: ChatMessage;
            if (!addToStartOfArray) {
                for (let i = this.messages.length - 1; i >= 0; i--) { //Start from End
                    m = this.messages[i];
                    if (Date.parse(message.sent_at) >= Date.parse(m.sent_at)) {
                        this.messages.splice(i + 1, 0, message);
                        break;
                    } else if (i == 0) {
                        this.messages.unshift(message);
                    }
                }

            } else {
                for (let i = 0; i < this.messages.length; i++) { //Start from Beginning
                    m = this.messages[i];
                    if (Date.parse(message.sent_at) <= Date.parse(m.sent_at)) {
                        this.messages.splice(i, 0, message);
                        break;
                    } else if (i == this.messages.length - 1) {
                        this.messages.push(message);
                    }
                }
            }
        }
    }


    /**
     * Adds multiple messages to the ChatRoom. It also looks for messages that initially could not be sent and removes them.
     * 
     * @param res Array of messages in json format.
     * @param addToStartOfArray 
     */
    addMessages(res: Array<any>, addToStartOfArray = false) {
        let newMessages: ChatMessage[] = ChatMessage.generateMultipleMessages(res);
        if (!newMessages || newMessages?.length == 0) {
            return;
        }
        newMessages.forEach(m => {
            let index = this.messages.findIndex(e => e.payload == m.payload && e.senderId == m.senderId && e.couldNotBeSent)
            if (index >= 0) {
                this.removeMessage(this.messages[index]);
            }
            this.addMessage(m, addToStartOfArray);
        })
    }

    messageCouldNotBeSent(message: ChatMessage) {
        message.couldNotBeSent = true;
    }

    /**
     * Checks if the message already exists in the ChatRoom. When there already is a message with the same sent_at-Date, senderId and payload the function returns true, else false. 
     * @param message 
     */
    messageAlreadyExists(message: ChatMessage): boolean {
        if (this.messages?.length <= 0) {
            return false;
        }
        let m: ChatMessage;
        let exists: boolean = false;
        for (let i = this.messages.length - 1; i >= 0; i--) {
            m = this.messages[i];
            if (m.sent_at == message.sent_at && m.senderId == message.senderId && m.payload == message.payload) {
                exists = true;
                break;
            }
        }
        return exists;
    }

    /**
     * Removes the ChatMessage-Object from the ChatRoom if it exists.
     * 
     * @param message 
     */
    removeMessage(message: ChatMessage) {
        const index: number = this._messages.indexOf(message);
        if (index != -1) {
            this._messages.splice(index, 1);
        }
    }

    /**
     * Returns the last message in the ChatRoom, that is not from you.
     */
    getLastForeignMessage(): ChatMessage {
        if (this.messages?.length <= 0) {
            return null;
        }
        let message: ChatMessage;
        let found = false;
        for (let i = this.messages.length - 1; i >= 0; i--) {
            message = this.messages[i];
            if (message.senderId != this.receiverId) {
                found = true;
                break;
            }
        }
        if (!found) {
            return null;
        }
        return message;
    }

    /**
     * Returns the last message in the ChatRoom.
     */
    getLastMessage(): ChatMessage {
        if (this.messages?.length <= 0) {
            return null;
        }
        return this.messages[this.messages.length - 1];
    }

    /**
     * Returns the first message in the ChatRoom.
     */
    getFirstMessage(): ChatMessage {
        return this.messages[0] || null;
    }

    isOwnMessage(message: ChatMessage): boolean {
        if (message.senderId == this.receiverId) {
            return true;
        } else {
            return false;
        }
    }

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get participants(): ChatParticipant[] {
        return this._participants;
    }
    public set participants(value: ChatParticipant[]) {
        this._participants = value;
    }

    public get meta() {
        return this._meta;
    }
    public set meta(value) {
        this._meta = value;
    }

    public get state(): string {
        return this._state;
    }
    public set state(value: string) {
        this._state = value;
    }

    public get messages(): ChatMessage[] {
        return this._messages;
    }
    public set messages(value: ChatMessage[]) {
        this._messages = value;
    }

    public get created_on(): string {
        return this._created_on;
    }
    public set created_on(value: string) {
        this._created_on = value;
    }

    public get receiverId(): string {
        return this._receiverId;
    }
    public set receiverId(value: string) {
        this._receiverId = value;
    }

}