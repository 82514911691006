import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { SessionService } from './session.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User;
  token: string;
  claims: any;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public route: ActivatedRoute,
    private session: SessionService,
    private api: ApiService
  ) {
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        this.user = user;
        let token = await user.getIdToken();
        this.loadClaims();
        this.token = token;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    });
  }

  checkInvitation(): any {
    let { company, key } = this.route.snapshot.queryParams;
    if (company && key) {
      return { company, key, query: `?company=${company}&key=${key}` };
    } else {
      return false;
    }
  }

  async login(email: string, password: string) {
    let invite = this.checkInvitation();
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        if (invite) return this.api.joinCompany(invite.company, invite.key);
      })
      .then((res) => {
        this.router.navigateByUrl(`/company`);
      });
  }

  async logout() {
    this.session.setSession();
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.router.navigate(['admin/login']);
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async reauthenticate(password: string) {
    let user = await this.afAuth.currentUser;
    let credential = auth.EmailAuthProvider.credential(user.email, password);
    return user.reauthenticateWithCredential(credential);
  }

  async changeUserPassword(newPassword: string) {
    this.afAuth.currentUser.then((user) => {
      return user.updatePassword(newPassword);
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async loginWithGoogle() {
    let _auth = await this.afAuth.signInWithPopup(
      new auth.GoogleAuthProvider()
    );
    await this.delay(500);
    let invite = this.checkInvitation();
    if (_auth.operationType == 'signIn') {
      if (invite) await this.api.joinCompany(invite.company, invite.key);
      this.router.navigateByUrl(`/company`);
    } else {
      this.router.navigateByUrl(`/admin/create-user${invite.query || ''}`);
    }
  }

  async register(email: string, pw: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, pw)
      .then(() => {
        let invite = this.checkInvitation();
        this.router.navigateByUrl(`/admin/create-user${invite.query || ''}`);
      })
      .catch((err) => {
        return err;
      });
  }

  loadClaims() {
    this.afAuth.currentUser
      .then((user) => {
        return user.getIdTokenResult();
      })
      .then((token) => {
        this.claims = token?.claims;
        return token?.claims;
      });
  }
}
