import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from '../models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  _session = {
    companies: null,
    company: null,
    user: null,
    active: false,
    licence: null,
  };

  isCompanyActive: boolean = true;
  currentCompanySubscriptions: Array<Subscription>;

  cChange: Subject<any> = new Subject();

  constructor(private api: ApiService, private router: Router) {}

  async initSession(attempt = 0) {
    try {
      let user = await this.api.getUser();
      let companies = await this.api.getCompanies();
      if (!companies || companies.length == 0) {
        this.router.navigate(['admin/create-company']);
        console.log('redirecting to register company');
      }
      this.setSession(companies, companies[0], user);
    } catch (error) {
      console.error(`Could not create session in attempt ${attempt}.`);
      if (error.status === 409) {
        this.router.navigate(['admin/create']);
        console.log('redirecting to register');
        return false;
      }
      if (error.status === 498) {
        this.router.navigate(['admin/login']);
        console.log('redirecting to login');
        return false;
      }
      if (attempt < 5) {
        this.initSession(attempt + 1);
      } else {
        this.setSession();
        throw error;
      }
    }
    return this._session;
  }

  setSession(companies = null, company = null, user = null) {
    if (!company) {
      localStorage.removeItem('activeCompanyId'); //When the company parameter is null, we want to clear the session object and therefore remove the key in localStorage
    }
    this._session.companies = _.sortBy(companies, ['company']);
    let activeCompanyId = localStorage.getItem('activeCompanyId');
    if (activeCompanyId) {
      this._session.company = this._session.companies.filter(
        (comp) => comp?._id == activeCompanyId
      )[0];
      if (!this._session.company) {
        //Fallback when no company with the id was found, we take the first one
        this._session.company = company;
      }
    } else {
      this._session.company = company;
    }
    if (this._session?.company?._id) {
      this.api
        .getCompany(this._session.company._id)
        .then((comp) => {
          this.currentCompanySubscriptions = comp.$subscriptions;
          this.isCompanyActive = comp.active_subscription;
        })
        .then(() => {
          return this.loadLicenses();
        });
    }

    this._session.user = user;
    if (companies && company && user) {
      this._session.active = true;
    } else {
      this._session.active = false;
    }
    return true;
  }

  setCompany(company) {
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;

    localStorage.setItem('activeCompanyId', company._id);
    this.setSession(this._session.companies, company, this._session.user);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.router.navigate(['/company']).then(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = prev;
      this.router.onSameUrlNavigation = prevOSN;
    });
  }

  setCompanyByName(company) {
    if (!company) return false;
    let c = this._session.companies.filter((x) => x.company === company)[0];
    this._session.company = c || this._session.companies[0];
    localStorage.setItem('activeCompanyId', c?._id);
    return true;
  }

  getAllSubscriptionRights() {
    if (!this.currentCompanySubscriptions) {
      return null;
    }
    let arr = [];
    this.currentCompanySubscriptions?.map((el) => {
      arr = arr.concat(el.rights);
    });
    return arr;
  }

  loadLicenses() {
    if (!this._session?.company?._id) return;
    return this.api.getLicenses(this._session?.company?._id).then((licence) => {
      this._session.licence = licence;
    });
  }
}
