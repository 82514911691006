import { UtilsService } from '../services/utils.service';

export class ChatParticipant {
  private _id: string;
  private _type: string;
  private _name: string;
  private _profilePicture: string;
  public profilePictureDownloadUrl: string;

  constructor(id: string, type: string, name: string, profilePicture: string) {
    this._id = id;
    this._type = type;
    this._name = name;
    this._profilePicture = profilePicture;
  }

  static generateOneParticipant(res) {
    let participant = new ChatParticipant(
      res.pid,
      res.type,
      res.name,
      res.profile_picture
    );
    if (participant.profilePicture) {
      UtilsService.instance
        .getImageDownloadUrl(participant.profilePicture)
        .then((downloadURL) => {
          participant.profilePictureDownloadUrl = downloadURL;
        });
    }
    return participant;
  }

  static generateMultipleParticipants(res: []): ChatParticipant[] {
    let participants: ChatParticipant[] = [];
    res?.forEach((part) => {
      participants.push(ChatParticipant.generateOneParticipant(part));
    });
    return participants;
  }

  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  public get profilePicture(): string {
    return this._profilePicture;
  }
  public set profilePicture(value: string) {
    this._profilePicture = value;
  }
}
