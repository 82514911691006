<app-nav id="navbar"></app-nav>
<div class="wrapper d-flex" style="padding-top: 56px; align-items: stretch">
  <app-sidebar id="sidebar" class="d-none d-md-block" *ngIf="_session.active">
  </app-sidebar>
  <div id="workspace">
    <div
      class="row w-100 bg-dark pl-3 py-2 mx-0 text-white"
      *ngIf="!isCompanyActive()"
      style="display: inline-block"
    >
      Dein Betrieb hat kein laufendes Abonnement. D.h. er ist nicht sichtbar für
      andere Nutzer auf der Plattform. Schließe ein Abonnement ab, um sichtbar
      für andere Nutzer zu sein! Schreibe einfach eine Mail an:
      <!-- <span id="abonnement" class="pointer" (click)="onClickAbo()">
        Abonnement abschließen</span
      > -->
      <span id="abonnement"> info@mehrmacher.de</span>
    </div>
    <app-notification></app-notification>
    <div class="container py-5" style="min-height: 100vh">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
