import { ChatParticipant } from '.';



export class ChatListItem {

    private _id: string;
    private _participants: ChatParticipant[];
    private _state: string;
    private _meta;
    private _unread: number;
    private _recentMessage;

    constructor(id: string, participants: ChatParticipant[], state: string, meta, unread: number, recentMessage) {
        this._id = id;
        this._participants = participants;
        this._state = state;
        this._meta = meta;
        this._unread = unread;
        this._recentMessage = recentMessage;
    }

    static generateOneItem(res): ChatListItem {
        return new ChatListItem(
            res._id,
            ChatParticipant.generateMultipleParticipants(res.participants),
            res.state,
            res.meta,
            res.unread,
            res.recent
        )
    }

    static generateMultipleItems(res: Array<any>): ChatListItem[] {
        let chatList: ChatListItem[] = [];
        res.forEach(element => {
            chatList.push(ChatListItem.generateOneItem(element));
        });
        return chatList;
    }

    public get recentMessage() {
        return this._recentMessage;
    }
    public set recentMessage(value) {
        this._recentMessage = value;
    }
    public get unread(): number {
        return this._unread;
    }
    public set unread(value: number) {
        this._unread = value;
    }
    public get meta() {
        return this._meta;
    }
    public set meta(value) {
        this._meta = value;
    }
    public get state(): string {
        return this._state;
    }
    public set state(value: string) {
        this._state = value;
    }
    public get participants(): ChatParticipant[] {
        return this._participants;
    }
    public set participants(value: ChatParticipant[]) {
        this._participants = value;
    }
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

}