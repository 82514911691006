<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
  <a class="navbar-brand" href="#">
    <img
      src="/assets/img/mm_schrift@2x.png"
      height="30"
      class="d-inline-block align-top"
      alt="mehrmacher"
    />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarTogglerDemo02"
    aria-controls="navbarTogglerDemo02"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav ml-auto mt-2 mt-lg-0" *ngIf="_session.active">
      <li
        class="d-md-none nav-item pointer px-3"
        *ngFor="let link of getSidebarLinks()"
      >
        <a
          class="nav-link"
          [routerLink]="link.routerLink"
          [queryParams]="link.queryParams"
          routerLinkActive="active"
          >{{ link.name }}</a
        >
      </li>
      <li
        class="nav-item pointer dropdown"
        *ngIf="_session.companies.length > 1"
      >
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ _session.company.company }}
        </a>
        <div
          class="dropdown-menu dropdown-menu-right dropdown-companies"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="dropdown-item"
            *ngFor="let company of _session.companies"
            (click)="changeCompany(company)"
            >{{ company.company }}</a
          >
        </div>
      </li>
      <li class="nav-item pointer dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            class="pb-1"
            src="/assets/svg/icon_settings.svg"
            alt="settings"
          />
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a class="dropdown-item" href="/company/create" *ngIf="canCreate"
            ><img
              class="pb-1 pr-1"
              src="/assets/svg/icon_add.svg"
              alt="create company"
            />
            Betrieb erstellen</a
          >
          <!-- <a class="dropdown-item" (click)="onClickStripe()">
            <img class="pb-1 pr-1" src="/assets/svg/icon_credit_card.svg" />
            Zahlungsinformationen bearbeiten
          </a> -->
          <a class="dropdown-item" href="/company/change-password"
            ><img
              class="pb-1 pr-1"
              src="/assets/svg/icon_change.svg"
              alt="change password"
            />
            Passwort ändern</a
          >
          <a class="dropdown-item" (click)="signOut()"
            ><img
              class="pb-1 pr-1"
              src="/assets/svg/icon_logout.svg"
              alt="sign out"
            />
            Abmelden</a
          >
        </div>
      </li>
    </ul>
  </div>
</nav>
