<ng-container *ngIf="licence">
  <div class="row">
    <div class="col-12 col-lg-4">
      <div class="card w-100 mb-2">
        <div class="card-body">
          <h5 class="card-title">Veröffentlichte Stellen</h5>
          <p class="card-text">{{ licence.jobLimit?.used }}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="card w-100 mb-2">
        <div class="card-body">
          <h5 class="card-title">Stellen Limit</h5>
          <p class="card-text">{{ licence.jobLimit?.limit }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 p-0 mt-5">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Deine Lizenzen</th>
          <th scope="col">Von</th>
          <th scope="col">Bis</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let l of licence.licences">
          <td>{{ getLicenceName(l) }}</td>
          <td>{{ l.valid_from | date: "dd.MM.yyyy" }}</td>
          <td>{{ l.valid_to | date: "dd.MM.yyyy" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
